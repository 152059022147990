import { useCategories } from "./useCategories";
import { useSearchResult } from "../hooks/useSearchResult";

export const useCategory = (id: number, searchValue: string) => {
  const searchResult = useSearchResult(searchValue);
  const searchArticles = searchResult?.data ? searchResult?.data[0] : null;
  const categories = useCategories();
  const category = (categories.data || []).find((category) => {
    return category.id === id;
  });

  if (searchValue) {
    return {
      categories: searchResult,
      category: searchArticles,
      menuCategories: categories,
      isSearch: true,
    };
  }
  return {
    categories,
    category,
    menuCategories: categories,
    isSearch: false,
  };
};
