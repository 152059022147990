import styled, { css } from "styled-components";

import { Paper } from "../../Paper";
import { Button } from "../../Button";

interface MenuItemProps {
  active: boolean;
}
interface AccordionTitleProps {
  isExpanded: boolean;
}

export const CategoryPage = styled.div`
  background-color: #f6f9fa;
  width: 100%;
  @media (max-width: 767px) {
    background-color: #ffffff;
  }
`;

export const MenuItem = styled.li<MenuItemProps>`
  color: #abb3b5;
  cursor: pointer;
  list-style-type: none;
  width: 292px;
  font-size: 17px;
  font-weight: bold;
  ${(props) =>
    props.active &&
    css`
      color: #4646eb;
      list-style-type: disc;
    `}
`;

export const CategoryContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-top: 62px;
  @media (max-width: 992px) {
    flex-direction: column-reverse;
    margin-top: 30px;
  }
  @media (max-width: 767px) {
    margin-top: 0;
  }
`;

export const AccordionTitle = styled.div<AccordionTitleProps>`
  font-size: 22px;
  font-weight: bold;
  line-height: 1.32;
  color: #263242;
  ${(props) =>
    props.isExpanded &&
    css`
      color: #4646eb;
    `}
`;

export const AccordionDescription = styled.div`
  font-size: 20px;
  color: #263242;
  line-height: 1.35;
  & span {
    font-size: 20px !important;
    color: #263242 !important;
    font-family: Montserrat, sans-serif !important;
  }
  & img {
    width: 100% !important;
  }
`;

export const MenuContainer = styled.ul`
  height: 454px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-between;
  margin: 0 0 122px 0;
  @media (max-width: 992px) {
    padding-left: 20px;
    margin: 0 0 90px 0;
  }
  @media (max-width: 767px) {
    margin: 0 0 60px 0;
  }
`;

export const PaperFAQ = styled(Paper)`
  padding: 36px 68px;
  flex-grow: 2;
  @media (max-width: 992px) {
    padding: 0 32px;
    margin-bottom: 54px;
  }
  @media (max-width: 767px) {
    box-shadow: none;
  }
`;

export const PaperSubmitTicket = styled(Paper)`
  padding: 24px !important;
  max-width: 262px;
  position: relative;
  margin-left: 40px;
  @media (max-width: 992px) {
    width: 100%;
    max-width: none;
    margin: 0 0 30px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const TitleSubmitTicket = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 1.35;
  color: #263242;
  margin-top: 70px;
  margin-bottom: 12px;
`;

export const DescriptionSubmitTicket = styled.div`
  font-size: 14px;
  line-height: 1.36;
  color: #79848d;
  margin-bottom: 24px;
`;

export const ButtonSubmitTicket = styled(Button)`
  width: 214px;
  height: 44px;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const LinkSubmitTicket = styled.a`
  position: absolute;
  width: 214px;
  height: 44px;
  top: 0;
  left: 0;
  font-size: 15px;
  font-weight: bold;
  text-align: center;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  @media (max-width: 400px) {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: absolute;
  width: 146px;
  height: 146px;
  top: -55px;
  left: 0;
`;

export const MenuQuestionBlock = styled.div`
  @media (max-width: 992px) {
    display: flex;
    flex-direction: column-reverse;
  }
  @media (max-width: 992px) and (min-width: 768px) {
    width: 100%;
  }
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
`;

export const EmptySearchTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #263242;
  margin-bottom: 8px;
`;

export const SearchTitle = styled.div`
  font-size: 28px;
  font-weight: bold;
  color: #263242;
  margin-bottom: 8px;
  margin-top: 20px;
`;

export const SearchValue = styled.span`
  color: #4f55f7;
`;

export const EmptySearchDescription = styled.div`
  font-size: 14px;
  color: #abb3b5;
  margin-bottom: 84px;
  max-width: 343px;
`;

export const IllustrationBlock = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
`;

export const IllustrationContainer = styled.div`
  width: 274px;
  height: 293px;
`;

export const EmptyResult = styled.div`
  @media (max-width: 992px) {
    padding: 32px 0;
  }
`;
