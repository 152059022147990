import React, { ReactElement } from "react";

import {
  PaperSubmitTicket,
  TitleSubmitTicket,
  DescriptionSubmitTicket,
  ButtonSubmitTicket,
  LinkSubmitTicket,
  ImageContainer,
} from "./styled";
import { ReactComponent as QuestionIcon } from "../../../assets/question.svg";

export const SubmitTicketBlock = (): ReactElement => {
  return (
    <PaperSubmitTicket>
      <ImageContainer>
        <QuestionIcon
          style={{ width: "100%", height: "100%" }}
          preserveAspectRatio="xMinYMin meet"
          viewBox="0 0 150 150"
        />
      </ImageContainer>
      <div>
        <TitleSubmitTicket>Gesucht und doch nichts gefunden?</TitleSubmitTicket>
        <DescriptionSubmitTicket>
          Manche Fragen klärt man am besten direkt.
        </DescriptionSubmitTicket>
      </div>
      <ButtonSubmitTicket>
        <LinkSubmitTicket href="mailto:hello@stromee.de">
          Schreib uns
        </LinkSubmitTicket>
      </ButtonSubmitTicket>
    </PaperSubmitTicket>
  );
};
