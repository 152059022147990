import React, { ReactElement } from "react";
import { navigate } from "@reach/router";

import { CategoryFAQ } from "../../../types";
import { MenuItem, MenuContainer } from "./styled";
interface MenuProps {
  categories: Array<CategoryFAQ> | null;
  categoryId?: number | null;
  isSearch: boolean;
  cleanSearchField: (value: string) => void;
}
export const Menu = ({
  categories,
  categoryId = null,
  isSearch,
  cleanSearchField,
}: MenuProps): ReactElement => {
  const onHandleClick = (id: number) => {
    navigate(`/faq-category/${id}`);
  };
  return (
    <MenuContainer>
      {categories &&
        categories?.map((category) => (
          <MenuItem
            key={category.id}
            active={category.id === categoryId && !isSearch}
            onClick={() => {
              cleanSearchField("");
              onHandleClick(category.id);
            }}
          >
            {category.name}
          </MenuItem>
        ))}
    </MenuContainer>
  );
};
