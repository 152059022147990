import React, { ReactElement, useState } from "react";

import { useCategory } from "../hooks/useCategory";
import { PreloaderContainerLayout } from "../../Contract/Layout/styled";
import { Article } from "../../../types";
import Preloader from "../../SVG/Preloader";
import NavBar from "../../NavBar";
import SearchBlock from "../Search/SearchBlock";
import { Accordion } from "./Accordion";
import { Menu } from "./Menu";
import {
  CategoryContainer,
  CategoryPage,
  PaperFAQ,
  MenuQuestionBlock,
  EmptySearchTitle,
  EmptySearchDescription,
  SearchValue,
  IllustrationBlock,
  IllustrationContainer,
  EmptyResult,
  SearchTitle,
} from "./styled";
import Footer from "../../Footer";
import { SubmitTicketBlock } from "./SubmitTicketBlock";
import { ReactComponent as EmptySearchIcon } from "../../../assets/illustration-faq.svg";
import { navigate } from "@reach/router";

interface CategoryProps {
  categoryId: string;
  searchRequest?: string;
}
const Category = ({
  categoryId,
  searchRequest,
}: CategoryProps): ReactElement => {
  const [searchValue, setSearchValue] = useState(searchRequest || "");
  const { categories, category, menuCategories, isSearch } = useCategory(
    Number(categoryId),
    searchValue
  );
  const onHandleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    navigate(`/faq-search/?value=${searchValue}`);
  };
  const getAnswersList = (answers: Array<Article>) => {
    return answers
      .filter((answer) => answer.status === 2)
      .map((answer, index) => {
        return (
          <Accordion key={answer.id} answer={answer} isExpanded={index === 0} />
        );
      });
  };
  const getEmptySearchResult = () => {
    return (
      <EmptyResult>
        <EmptySearchTitle>
          Leider nichts gefunden zu <SearchValue>{searchValue}</SearchValue>
        </EmptySearchTitle>
        <EmptySearchDescription>
          Wir haben gesucht, aber zu deiner Frage haben wir anscheinend noch
          keine Antwort vorbereitet.
          <br />
          Bitte nimm Kontakt zu unserem Support auf.
        </EmptySearchDescription>
        <IllustrationBlock>
          <IllustrationContainer>
            <EmptySearchIcon
              style={{ width: "100%", height: "100%" }}
              preserveAspectRatio="xMinYMin meet"
              viewBox="0 0 274 293"
            />
          </IllustrationContainer>
        </IllustrationBlock>
      </EmptyResult>
    );
  };
  return categories.isPending ||
    menuCategories.isPending ||
    !categories.data ? (
    <PreloaderContainerLayout>
      <Preloader />
    </PreloaderContainerLayout>
  ) : (
    <CategoryPage>
      <NavBar />
      <div className="container">
        <div className="row">
          <div className="col-12"></div>
        </div>
      </div>
      <SearchBlock defaultValue={searchValue} onHandleSearch={onHandleSearch} />
      <div>
        <div className="container">
          <div className="row">
            <CategoryContainer className="col-12">
              <MenuQuestionBlock>
                <Menu
                  categories={menuCategories.data}
                  categoryId={Number(categoryId)}
                  isSearch={isSearch}
                  cleanSearchField={setSearchValue}
                />
                <SubmitTicketBlock />
              </MenuQuestionBlock>
              <PaperFAQ>
                {searchValue && category?.answers?.length ? (
                  <SearchTitle>
                    Hier sind deine Suchergebnisse für{" "}
                    <SearchValue>{searchValue}</SearchValue>
                  </SearchTitle>
                ) : (
                  ""
                )}
                {category?.answers?.length
                  ? getAnswersList(category.answers)
                  : getEmptySearchResult()}
              </PaperFAQ>
            </CategoryContainer>
          </div>
        </div>
        <Footer isFaqPage={true} />
      </div>
    </CategoryPage>
  );
};

export default Category;
