import { useEffect, useState } from "react";

import { getSearchResult } from "../../../services/faq";
import { SearchResult } from "../../../types";
import { createRequestState } from "../../../utils";

export const useSearchResult = (searchValue: string) => {
  const [categories, setCategories] = useState(
    createRequestState<SearchResult[]>()
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        setCategories({ isPending: true, data: null, error: null });
        const categories = await getSearchResult(searchValue);
        const categoriesState = {
          isPending: false,
          data: [{ answers: categories }],
          error: null,
        };
        setCategories(categoriesState);
      } catch (error) {
        const categoriesState = { isPending: false, data: null, error };
        setCategories(categoriesState);
      }
    };
    searchValue.length && fetchData();
  }, [searchValue]);
  return categories;
};
