import React, { useState } from "react";
import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import parse from "html-react-parser";

import { Article } from "../../../types";
import { AccordionTitle, AccordionDescription, IconContainer } from "./styled";
import { ReactComponent as IconMinus } from "../../../assets/minus.svg";
import { ReactComponent as IconPlus } from "../../../assets/plus.svg";

type SVGReactComponent = React.SFC<React.SVGProps<SVGSVGElement>>;

interface AccordionProps {
  answer: Article;
  isExpanded: boolean;
}

const getIconComponent = (Icon: SVGReactComponent) => {
  return (
    <IconContainer>
      <Icon
        style={{ width: "100%", height: "100%" }}
        preserveAspectRatio="xMinYMin meet"
        viewBox="0 0 32 32"
      />
    </IconContainer>
  );
};

const AccordionPanel = withStyles(() => ({
  root: {
    boxShadow: "none",
    padding: "20px 0",
    margin: "0!important",
    borderBottom: "1px solid #d5dbdd",
  },
}))(ExpansionPanel);

const AccordionSummary = withStyles(() => ({
  root: {
    padding: 0,
    margin: "0",
  },
}))(ExpansionPanelSummary);

const AccordionDetails = withStyles(() => ({
  root: {
    padding: 0,
    paddingBottom: "12px",
  },
}))(ExpansionPanelDetails);

export const Accordion = ({ answer, isExpanded }: AccordionProps) => {
  const [expanded, setExpanded] = useState(isExpanded);
  return (
    <AccordionPanel
      defaultExpanded={isExpanded}
      onChange={(event, expanded) => {
        setExpanded(expanded);
      }}
    >
      <AccordionSummary
        expandIcon={
          expanded ? getIconComponent(IconMinus) : getIconComponent(IconPlus)
        }
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <AccordionTitle isExpanded={expanded}>{answer.title}</AccordionTitle>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDescription>{parse(answer.description)}</AccordionDescription>
      </AccordionDetails>
    </AccordionPanel>
  );
};
